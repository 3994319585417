import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import Link from "gatsby-link"

const Content = ({image}) => (
  <StaticQuery
    query={graphql`
      query {
        whatis: file(relativePath: { eq: "whatis.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        whois: file(relativePath: { eq: "whois.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        howdo: file(relativePath: { eq: "howdo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
      }
    `}
    render={data => {
      // Set ImageData.
      const whatis = data.whatis.childImageSharp.fluid
      //console.log(data)
      const whois = data.whois.childImageSharp.fluid
      const howdo = data.howdo.childImageSharp.fluid
      return (
        <Container className="information">
          <Row>
            <Col md={12}>
              <h1>Welcome to The Lake District</h1>
              <p><a href="https://en.wikipedia.org/wiki/Lake_District" target="_blank" rel="noopener noreferrer">The Lake District</a>, also known as the Lakes or Lakeland, is a mountainous region in  <a href="https://en.wikipedia.org/wiki/Cumbria" target="_blank" rel="noopener noreferrer">Cumbria</a>, North West England. A popular holiday destination, it is famous for its lakes, forests and mountains (or fells) and its associations with the early 19th century writings of <a href="https://en.wikipedia.org/wiki/William_Wordsworth" target="_blank" rel="noopener noreferrer">William Wordsworth</a> and the other <a href="https://en.wikipedia.org/wiki/Lake_Poets" target="_blank" rel="noopener noreferrer">Lake Poets</a>, <a href="https://en.wikipedia.org/wiki/Beatrix_Potter" target="_blank" rel="noopener noreferrer">Beatrix Potter</a> and <a href="https://en.wikipedia.org/wiki/John_Ruskin" target="_blank" rel="noopener noreferrer">John Ruskin</a>. A National Park was established in 1951 and, following a minor extension in 2016, now covers an area of approximately 2,362 square kilometres. It was designated as a <a href="https://whc.unesco.org/en/list/422" target="_blank" rel="noopener noreferrer">UNESCO World Heritage Site</a> in 2017.</p>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h2>What is The Lake District Guide?</h2>
            </Col>
              <Col md={6}>
                <p>The Lake District Guide is an online resource for information about The Lake District in Cumbria including but not limited to information about the lakes, tarns, fells, the many available walks, and local attractions for staying here and enjoying this beautiful part of the world.</p>
                <p>You can access The Lake District Guide on any device by going to <Link to='/'>thelakedistrictguide.com</Link>. You can also add to the homescreen of your movile device or install as a <a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="_blank" rel="noopener noreferrer">PWA</a>.</p>
            </Col>
            <Col md={6}>
              <Img fluid={whatis} />
            </Col>
            
          </Row>
          <hr />
          <Row>
            <Col md={12}>
              <h3>Who is The Lake District Guide for?</h3>
            </Col>
            <Col md={8}>
              <p>
                The Lake District Guide is for anyone (visitor or local) who wants to learn more or access information about The Lake District. We offer information and recommmendations about visiting and staying in The Lake District.
              </p>
              <p>
                We are constantly striving to make our service more accessible and more usable for everyone. If you have any suggestions regarding how we can make The Lake District guide better, please get in touch with us.
              </p>
            </Col>
            <Col md={4}>
              <Img fluid={whois} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12}>
              <h3>How do I use The Lake District Guide?</h3>
            </Col>
            <Col md={8}>
              <p>
                The Lake District is a visitor's guide that aims to provide enough helpful information about The Lake District in one place so as to be useful to people. You can use it like a normal website.
              </p>
              <p>
                Whether you are planning a day trip or a longer visit, you can use The Lake District Guide to find places to stay, things to do, and also browse the <Link to='/lakes'>lakes</Link> and <Link to='/fells'>fells</Link>.
              </p>
            </Col>
            <Col md={4}>
              <Img fluid={howdo} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12}>
              <h3>How to carry on</h3>
              <p>
                If you would like to contribute to The Lake District Guide or, if you would like to become a sponsor please contact us.
              </p>
            </Col>
          </Row>
        </Container>
      )
    }}
  />
)

export default Content
